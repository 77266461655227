import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import apiService from '../../services/apiService';
import ProgressBar from '../Common/ProgressBar';
import queryString from 'query-string';
import NavBar from '../Common/NavBar';
import CopyrightInfo from '../Common/CopyrightInfo';
import PrivateRoute from '../../components/PrivateRoute';
import { useAuth } from '../../context/AuthContext';
import { MdOutlineArrowBack } from "react-icons/md";

import LeadFormButtons from './LeadFormButtons';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';

const LeadForm = () => {
    const location = useLocation();
    const queryParams = queryString.parse(location.search);
    const urlToken = queryParams.token;
    const navigate = useNavigate();
    const [currentStep, setCurrentStep] = useState(1);
    const totalSteps = 3;
    const [leadToken, setLeadToken] = useState(localStorage.getItem('authToken'));
    const [formData, setFormData] = useState({
        amount: '',
        birthDate: '',
        civilStatus: '',
        client: {},
        contractSince: '',
        contractType: '',
        creditType: '',
        deadline: '',
        email: '',
        employerType: '',
        gender: '',
        housingType: '',
        moreMoney: '',
        name: '',
        country: '',
        nif: '',
        objective: '',
        paymentDay: '',
        personalAmount: '',
        phone: '',
        rentDate: '',
        rentHome: '',
        salary: '',
        sinceCompany: '',
    });

    const [form2Data, setForm2Data] = useState({
        relations: '',
        secondHolderBirthDate: '',
        secondHolderName: '',
        secondHolderGender: '',
        secondHolderMaritalStatus: '',
        secondHolderNif: '',
        secondHolderPhone: '',
        secondHolderEmail: '',
        secondHolderCountry: '',
        secondHolderEmployerType: '',
        secondHolderContractType: '',
        sencondHolderContractSince: '',
        secondHolderAmountSalary: '',
    });

    const [allDataOptions, setAllDataOptions] = useState({
        civilStatus: [],
        contractType: [],
        employerType: [],
        gender: [],
        housingType: [],
        country: [],
        purpose: [],
    });

    const [selectedOption, setSelectedOption] = useState('no');  // Default to 'no'
    const [isChecked, setIsChecked] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [name, setClientName] = useState('');
    const [loading, setLoading] = useState(false);
    const [apiData, setApiData] = useState(null);
    const [holderCount, setHolderCount] = useState(0);
    const [processData, setProcessData] = useState([]);
    const [approvalStatus, setApprovalStatus] = useState(null);

    const requiredFieldsByStep = {
        1: ['amount', 'birthDate', 'civilStatus', 'client', 'contractSince', 'contractType', 'creditType', 'deadline', 'email', 'employerType', 'gender', 'housingType', 'name', 'country', 'nif', 'objective', 'paymentDay', 'personalAmount', 'phone', 'salary', 'sinceCompany'],
        2: ['relations', 'secondHolderBirthDate', 'secondHolderName', 'secondHolderGender', 'secondHolderMaritalStatus', 'secondHolderNif', 'secondHolderPhone', 'secondHolderEmail', 'secondHolderCountry', 'secondHolderEmployerType', 'secondHolderContractType', 'sencondHolderContractSince', 'secondHolderAmountSalary'],
        3: []
    };

    const addSecondHolder = async () => {
        try {
            const processId = localStorage.getItem('processId'); // Assuming processId is stored in local storage
            if (!processId) {
                console.error("Process ID not found.");
                return;
            }

            // Update the lead status to '2'
            const updateLeadStatusResponse = await apiService.updateLeadStatus(processId, 2);
            console.log('Lead status update to 2 successful:', updateLeadStatusResponse);

            window.dataLayer.push({
                event: 'add_second_holder_click',
                category: 'Lead Form',
                action: 'Add Second Holder',
                label: 'Add Second Holder Button Clicked'
            });

            // Now change the step to StepTwo
            setCurrentStep(2);
        } catch (error) {
            console.error("Failed to update lead status to 2:", error);
        }
    };

    const validateCurrentStep = () => {
        let isValid = true;
        let errors = {};
        let requiredFields = requiredFieldsByStep[currentStep] || [];

        if (currentStep === 2 && selectedOption === 'yes') {
            requiredFields = requiredFields.concat([
                'secondHolderBirthDate',
                'secondHolderName',
                'secondHolderGender',
                'secondHolderMaritalStatus',
                'secondHolderNif',
                'secondHolderPhone',
                'secondHolderEmail',
                'secondHolderCountry',
                'secondHolderEmployerType',
                'secondHolderContractType',
                'sencondHolderContractSince',
                'secondHolderAmountSalary',
            ]);
        }

        requiredFields.forEach(field => {
            const fieldValue = formData[field] !== undefined ? formData[field] : form2Data[field];

            if (typeof fieldValue === 'string') {
                if (!fieldValue.trim()) {
                    isValid = false;
                    errors[field] = 'Campo de preenchimento obrigatório';
                }
            } else if (typeof fieldValue === 'number') {
                if (isNaN(fieldValue)) {
                    isValid = false;
                    errors[field] = 'Campo de preenchimento obrigatório';
                }
            } else if (typeof fieldValue === 'object') {
                if (!fieldValue || Object.keys(fieldValue).length === 0) {
                    isValid = false;
                    errors[field] = 'Campo de preenchimento obrigatório';
                }
            }
        });

        setFormErrors(errors);
        return isValid;
    };

    const putClienteInfo = async () => {
        try {
            const processId = localStorage.getItem('processId');
            let holderNumber;
            let dataForUpdate;

            if (currentStep === 1) {
                holderNumber = "1";
                dataForUpdate = formData;
            } else if (currentStep === 2 && selectedOption === 'yes') {
                holderNumber = "2";
                dataForUpdate = form2Data;
            } else {
                holderNumber = "1";
                dataForUpdate = formData;
            }

            const dataToUpdate = {
                [holderNumber]: {
                    ...dataForUpdate
                }
            };

            const response = await apiService.updateLeadData(processId, dataToUpdate);
            console.log('Update successful:', response);
        } catch (error) {
            console.error('Error submitting modal form:', error);
        }
    };

    useEffect(() => {
        let isMounted = true;

        if (!urlToken) {
            navigate('/login');
            return;
        };

        if (urlToken !== leadToken) {
            console.log('New urlToken detected. Updating localStorage.');
            localStorage.setItem('authToken', urlToken);
            setLeadToken(urlToken);
        };

        const fetchInitialData = async () => {
            if (!leadToken) return;

            try {
                const apiData = await apiService.fetchInitialData(leadToken);
                const procProcess = apiData.proc_processes && apiData.proc_processes[0];
                const procProcessClient = procProcess && procProcess.proc_process_clients && procProcess.proc_process_clients[0];
                let procProcessClient2Holder = null;

                if (procProcess && procProcess.proc_process_clients) {
                    setHolderCount(procProcess.proc_process_clients.length);

                    if (procProcess.proc_process_clients.length >= 2) {
                        procProcessClient2Holder = procProcess.proc_process_clients[1];
                    }
                }

                if (isMounted) {
                    setApiData(apiData);
                    setClientName(apiData.client[0].name || '');
                    localStorage.setItem('processId', procProcess.id);

                    const detailedProcessData = await apiService.fetchProcessDetails(procProcess.id);

                    if (detailedProcessData.state === 3) {
                        navigate(`/documentos?token=${leadToken}`);
                        return;
                    }

                    const initializeFormData = () => {
                        const baseFormData = {
                            birthDate: procProcessClient.birthDate || '',
                            civilStatus: procProcessClient.civilStatus || '',
                            contractType: procProcessClient.contractType || '',
                            email: procProcessClient.email || '',
                            employerType: procProcessClient.employerType || '',
                            gender: procProcessClient.gender || '',
                            housingType: procProcessClient.housingType || '',
                            name: procProcessClient.name || '',
                            country: procProcessClient.country || '',
                            nif: procProcessClient.nif || '',
                            phone: procProcessClient.phone || '',
                            rentDate: procProcessClient.rentDate || '',
                            rentHome: procProcessClient.rentHome || '',
                            salary: procProcessClient.salary || '',
                            sinceCompany: procProcessClient.sinceCompany || '',
                        };
                        return baseFormData;
                    };

                    if (procProcessClient2Holder) {
                        const initializeForm2Data = () => {
                            const baseForm2Data = {
                                birthDate: procProcessClient2Holder.birthDate || '',
                                civilStatus: procProcessClient2Holder.civilStatus || '',
                                contractType: procProcessClient2Holder.contractType || '',
                                email: procProcessClient2Holder.email || '',
                                employerType: procProcessClient2Holder.employerType || '',
                                gender: procProcessClient2Holder.gender || '',
                                housingType: procProcessClient2Holder.housingType || '',
                                name: procProcessClient2Holder.name || '',
                                country: procProcessClient2Holder.country || '',
                                nif: procProcessClient2Holder.nif || '',
                                phone: procProcessClient2Holder.phone || '',
                                rentDate: procProcessClient2Holder.rentDate || '',
                                rentHome: procProcessClient2Holder.rentHome || '',
                                salary: procProcessClient2Holder.salary || '',
                                sinceCompany: procProcessClient2Holder.sinceCompany || '',
                                relations: procProcessClient2Holder.relations || '',
                            };
                            return baseForm2Data;
                        }
                        setForm2Data(initializeForm2Data());
                    }
                    setFormData(initializeFormData());
                }
            } catch (error) {
                console.error("Failed to fetch lead info", error);
                if (isMounted) {
                    navigate('/error');
                }
            }
        };

        const fetchAllDataOptions = async () => {
            try {
                const allDataOptions = await apiService.fetchAllDataOptions();
                setAllDataOptions(allDataOptions);
            } catch (error) {
                console.error("Failed to fetch all data options", error);
            }
        };

        fetchInitialData();
        fetchAllDataOptions();

        return () => {
            isMounted = false;
        };
    }, [leadToken, navigate, urlToken]);

    const getCreditTypeDescriptionById = (creditTypeId) => {
        const creditTypeObj = allDataOptions.creditTypes?.find(ct => ct.id === creditTypeId);
        return creditTypeObj ? creditTypeObj.credit_type : 'Unknown Type';
    };

    const simulateProcess = async () => {
        setLoading(true);
        const processId = localStorage.getItem('processId');

        window.dataLayer.push({
            event: 'simulate_process_click',
            category: 'Lead Form',
            action: 'Simulate Process',
            label: 'Simulate Process Button Clicked'
        });

        try {
            if (holderCount === 1 && selectedOption === 'yes') {
                const newHolderData = {
                    name: form2Data.name,
                    phone: form2Data.phone,
                    email: form2Data.email
                };

                try {
                    const secondHolder = await apiService.createSecondHolder(processId, newHolderData);
                    console.log('Second Holder Created:', secondHolder);
                    setHolderCount(holderCount + 1);
                } catch (createError) {
                    console.error('Failed to create the second holder:', createError);
                }
            }

            await putClienteInfo();
            console.log('Holder Updated Successfully');

            const detailedProcessData = await apiService.fetchProcessDetails(processId);
            console.log(detailedProcessData);
            setProcessData(detailedProcessData);

            const isValid = await validateLeadApproval(detailedProcessData);
            setApprovalStatus(isValid);
            console.log('Lead approval status:', isValid);

            setCurrentStep(totalSteps);

            const updateLeadStatusResponse = await apiService.updateLeadStatus(processId, 3);
            console.log('Lead status update successful:', updateLeadStatusResponse);

        } catch (error) {
            console.error('Failed to simulate the process:', error);
        } finally {
            setLoading(false);
        }
    };

    const validateLeadApproval = async (detailedProcessData) => {
        try {
            const response = await apiService.verifyRules(detailedProcessData);
            const isValid = response.data.isValid;
            console.log('Lead approval status:', isValid);
            return isValid;
        } catch (error) {
            console.error('Error validating lead:', error);
            return false;
        }
    };

    const onlySimulation = async () => {
        const processId = localStorage.getItem('processId');
        if (!processId) {
            console.error("No process ID found.");
            return;
        }

        try {
            const updateLeadStatusResponse = await apiService.updateLeadStatus(processId, 1);

            window.dataLayer.push({
                event: 'only_simulation_click',
                category: 'Lead Form',
                action: 'Only Simulation',
                label: 'Only Simulation Button Clicked'
            });

            console.log('Lead status update successful:', updateLeadStatusResponse);
            navigate('/dashboard');
        } catch (error) {
            console.error('Failed to update lead status:', error);
        }
    };

    const preApproval = async () => {
        const processId = localStorage.getItem('processId');
        const authToken = localStorage.getItem('authToken');

        if (!processId || !authToken) {
            console.error("No process ID found or AuthToken.");
            return;
        }

        try {
            const updateLeadStatusResponse = await apiService.updateLeadStatus(processId, 4);

            window.dataLayer.push({
                event: 'pre_approval_click',
                category: 'Lead Form',
                action: 'Pre-Approval',
                label: 'Pre-Approval Button Clicked'
            });

            console.log('Lead status update successful.', updateLeadStatusResponse)
            navigate(`/documentos?token=${authToken}`);

        } catch (error) {
            console.error('Failed to update lead status.', error);
        }
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleDateChange = (event) => {
        const { name, value } = event.target;

        // Validate if the date format is correct
        if (value && /^\d{4}-\d{2}-\d{2}$/.test(value)) {
            const date = new Date(value);

            if (!isNaN(date.getTime())) {
                handleInputChange({ target: { name, value: date.toISOString() } });
            } else {
                console.error("Invalid date format. Please enter a valid date.");
                handleInputChange({ target: { name, value: '' } });
            }
        } else {
            console.error("Invalid date format. Please use the format YYYY-MM-DD.");
            handleInputChange({ target: { name, value: '' } });
        }
    };

    // Prevent any non-calendar input by capturing key events
    const preventManualInput = (event) => {
        event.preventDefault();
    };

    const handle2InputChange = (event) => {
        const { name, value } = event.target;
        setForm2Data(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handle2DateChange = (event) => {
        const { name, value } = event.target;
        let isoDate = value;
        if (name === 'birthDate') {
            // For birthDate, just pass the date in YYYY-MM-DD format
            handle2InputChange({ target: { name, value } });
        } else {
            // For other dates, convert to ISO string (UTC)
            isoDate = new Date(value).toISOString();
            handle2InputChange({ target: { name, value: isoDate } });
        }
    };

    const isStepValid = useMemo(() => validateCurrentStep(), [formData, form2Data, currentStep, selectedOption]);

    const nextStep = () => {
        if (isStepValid) {

            putClienteInfo();

            window.dataLayer.push({
                event: 'next_step_click',
                category: 'Lead Form',
                action: 'Next Step',
                label: `Moved to Step ${currentStep + 1}`
            });

            console.log('Holder Updated Successfully');

            setCurrentStep(prev => Math.min(prev + 1, 3));
        }
    };

    const prevStep = () => {

        window.dataLayer.push({
            event: 'prev_step_click',
            category: 'Lead Form',
            action: 'Previous Step',
            label: `Moved to Step ${currentStep - 1}`
        });

        setCurrentStep(prev => Math.max(prev - 1, 1));
    };

    return (
        <div>
            <NavBar />
            <div className="flex justify-center bg-amco-gold container-noNav">
                <div className="w-full soft-bg roundContainer p-10 ml-5 mr-5 max-w-screen-xl">
                    <h2 className="pb-8 text-xl">Olá <span className="font-bold">{name}</span>!<br />Está a um passo de obter uma estimativa de prestação para o seu pedido de crédito. 🎉</h2>
                    <ProgressBar currentStep={currentStep} totalSteps={totalSteps} />
                    <p className="py-4 text-s font-bold">Preencha o seguinte formulário e acelere a pré-aprovação do seu crédito.</p>

                    <form className="mt-8 space-y-6">
                        {currentStep === 1 && (
                            <StepOne
                                formData={formData}
                                handleInputChange={(e) => setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }))}
                                handleDateChange={(e) => setFormData(prev => ({ ...prev, [e.target.name]: new Date(e.target.value).toISOString() }))}
                                formErrors={formErrors}
                                allDataOptions={allDataOptions}
                                isChecked={isChecked}
                                setIsChecked={setIsChecked}
                                selectedOption={selectedOption}
                                setSelectedOption={setSelectedOption}
                                preventManualInput={preventManualInput}
                            />
                        )}

                        {currentStep === 2 && (
                            <StepTwo
                                form2Data={form2Data}
                                apiData={apiData}
                                nextStep={nextStep}
                                handle2InputChange={(e) => setForm2Data(prev => ({ ...prev, [e.target.name]: e.target.value }))}
                                handle2DateChange={(e) => setForm2Data(prev => ({ ...prev, [e.target.name]: new Date(e.target.value).toISOString() }))}
                                formErrors={formErrors}
                                allDataOptions={allDataOptions}
                                isChecked={isChecked}
                                setIsChecked={setIsChecked}
                                selectedOption={selectedOption}
                                setSelectedOption={setSelectedOption}
                                preventManualInput={preventManualInput}
                            />
                        )}

                        {currentStep === 3 && (
                            <StepThree
                                formData={formData}
                                form2Data={form2Data}
                                apiData={apiData}
                                nextStep={nextStep}
                                getCreditTypeDescriptionById={(id) => allDataOptions.creditTypes?.find(ct => ct.id === id)?.credit_type || 'Unknown Type'}
                                onlySimulation={onlySimulation}
                                approvalStatus={approvalStatus}
                                processData={processData}
                                preApproval={preApproval}
                                addSecondHolder={addSecondHolder}
                            />
                        )}

                        <LeadFormButtons
                            currentStep={currentStep}
                            selectedOption={selectedOption}
                            isStepValid={isStepValid}
                            loading={loading}
                            nextStep={nextStep}
                            prevStep={prevStep}
                            simulateProcess={simulateProcess}
                            onlySimulation={onlySimulation}
                            preApproval={preApproval}
                            addSecondHolder={addSecondHolder}
                            formErrors={formErrors}
                        />
                    </form>

                    <div className="grid md:grid-cols-2 sm:grid-cols-1 gap-0 mt-12">
                        <CopyrightInfo />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LeadForm;

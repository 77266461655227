import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { MdCheckCircle, MdCloudUpload, MdWarning, MdClose, MdPhotoCamera, MdManageSearch } from 'react-icons/md';
import { GrDocumentPdf, GrDocumentImage, GrDocumentConfig } from "react-icons/gr";
import apiService from '../../services/apiService';

const MAX_FILES = 5;
const MAX_TOTAL_SIZE_MB = 10;
const MAX_TOTAL_SIZE_BYTES = MAX_TOTAL_SIZE_MB * 1024 * 1024;

const DocumentUpload = ({ doc, onFileUpload, onFileRemove, onDeleteFile, uploaded, process }) => {
    const [files, setFiles] = useState([]);
    const [isUploaded, setIsUploaded] = useState(!!doc.file);
    const fileInputRef = useRef(null);
    const cameraInputRef = useRef(null);
    const [isMobile, setIsMobile] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [totalSize, setTotalSize] = useState(0);

    useEffect(() => {
        if (doc.file) {
            setFiles([{
                name: doc.file.split('/').pop(),
                path: doc.file,
                size: 0,
                uploaded: true,
            }]);
        }
    }, [doc]);

    useEffect(() => {
        const checkMobile = () => setIsMobile(window.innerWidth < 768);
        checkMobile();
        window.addEventListener('resize', checkMobile);
        return () => window.removeEventListener('resize', checkMobile);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: (acceptedFiles) => handleFileSelect(acceptedFiles),
        accept: 'image/*, application/pdf',
        multiple: true,
        noClick: totalSize >= MAX_TOTAL_SIZE_BYTES || isUploaded || doc.validation === false,
        noKeyboard: totalSize >= MAX_TOTAL_SIZE_BYTES || isUploaded || doc.validation === false,
    });

    const handleFileSelect = (newFiles) => {
        const filesArray = Array.isArray(newFiles) ? newFiles : [newFiles];
        const newTotalSize = filesArray.reduce((acc, file) => acc + file.size, totalSize);
        const currentFileCount = files.length;
        const newFileCount = filesArray.length;

        if (currentFileCount + newFileCount > MAX_FILES) {
            alert(`You can only upload up to ${MAX_FILES} files.`);
            return;
        }

        if (totalSize + newTotalSize > MAX_TOTAL_SIZE_BYTES) {
            alert(`Total file size limit of ${MAX_TOTAL_SIZE_MB} MB exceeded!`);
            return;
        }

        const validFiles = filesArray.map((file) => ({
            file,
            name: file.name,
            size: file.size,
            thumbnail: URL.createObjectURL(file),
            uploaded: false,
        }));

        setFiles((prevFiles) => [...prevFiles, ...validFiles]);
        setTotalSize((prevTotalSize) => prevTotalSize + newTotalSize);
    };

    const handleCameraCapture = (event) => {
        const cameraFile = event.target.files[0];
        if (cameraFile) {
            handleFileSelect(cameraFile);
        }
    };

    const handleUpload = async () => {
        if (files.length === 0) return;

        setIsUploading(true);
        const processId = localStorage.getItem('processId');

        const dataForm = new FormData();
        files.forEach((file) => {
            if (!file.uploaded) {
                dataForm.append('file', file.file);
            }
        });
        dataForm.append('docId', doc.id);

        try {
            const response = await apiService.uploadDocument(processId, dataForm, {
                headers: { "Content-Type": "multipart/form-data" },
                onUploadProgress: (progressEvent) => {
                    const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setProgress(progress);
                },
            });

            console.log('Files uploaded and combined successfully', response);

            setFiles((prevFiles) =>
                prevFiles.map((f) => ({ ...f, uploaded: true }))
            );
            onFileUpload && onFileUpload(response);
            setIsUploaded(true);
        } catch (error) {
            console.error('Error uploading files', error);
        } finally {
            setIsUploading(false);
        }
    };

    const handleRemoveFile = (fileName) => {
        const fileToRemove = files.find(file => file.name === fileName);
        if (!fileToRemove) return;

        setFiles((prevFiles) => prevFiles.filter(file => file.name !== fileName));
        setTotalSize((prevTotalSize) => prevTotalSize - fileToRemove.size);

        if (files.length === 1) {
            setIsUploaded(false);
            onDeleteFile && onDeleteFile(doc.id);
        }
    };

    const getFileIcon = (fileName) => {
        if (!fileName) return null;

        const automaticDocuments = [
            "Mapa de Responsabilidades",
            "Comprovativo de Morada",
            "Último mapa de responsabilidades do Banco de Portugal disponível",
            "Último Modelo 3 do IRS"
        ];

        if (fileName.endsWith('.pdf')) {
            return (
                <div className="inline-flex items-center px-2 h-7 rounded-md text-sm text-10 font-bold bg-fuchsia-300 text-fuchsia-800 leading-none uppercase mr-4">
                    <GrDocumentPdf className="mr-2 text-fuchsia-800" /> PDF
                </div>
            );
        }

        if (fileName.match(/\.(jpeg|jpg|png|gif|bmp)$/i)) {
            return (
                <div className="inline-flex items-center px-2 h-7 rounded-md text-sm text-10 font-bold bg-yellow-300 text-yellow-800 leading-none uppercase mr-4">
                    <GrDocumentImage className="mr-2 text-yellow-800" /> IMAGEM
                </div>
            );
        }
        return null;
    };

    return (
        <div {...getRootProps()} className={`dropzone bg-[#616161]/5 p-6 mb-4 rounded-md ${uploaded ? 'dropzone--disabled' : ''} ${isUploaded ? 'bg-green/10' : ''}`}>
            <div className="flex justify-between">
                <p className="text-dark font-semibold">{doc.name}</p>
                {isUploaded ? <MdCheckCircle size={22} className="text-lime-500" /> : <MdWarning size={22} className="text-orange-500" />}
            </div>

            <p className="text-sm text-dark mb-4 md:pr-40 sm:pr-0">{doc.description}</p>

            <div
                className={
                    files.length > 0
                        ? 'justify-between border-dashed border-2 rounded-md p-2 border-dark/15 overflow-hidden'
                        : `justify-between border-dashed border-2 rounded-md p-2 border-dark/15 md:flex sm:block grid md:grid-cols-3 sm:grid-cols-1 overflow-hidden relative ${isDragActive ? 'bg-[#616161]/15' : ''}`
                }
            >

                {files.length === 0 && doc.validation !== false && (
                    <>
                        {!isMobile && (
                            <p className="flex col-span-2 py-2 text-dark/40 font-semibold text-sm text-12">
                                {isDragActive ? 'Largue aqui o seu ficheiro...' : 'Arraste para aqui o seu ficheiro ou clique no botão para carregar documento'}
                            </p>
                        )}
                        <div className="flex">
                            {isMobile && (
                                <button
                                    type="button"
                                    className="flex items-center bg-gray-600 text-white py-2 px-4 rounded text-sm mr-2"
                                    onClick={() => cameraInputRef.current.click()}
                                >
                                    <MdPhotoCamera size={20} />
                                </button>
                            )}

                            <button
                                type="button"
                                className="justify-self-end flex w-fit amco-blue hover:amco-blue/75 text-white font-normal py-2 px-4 rounded cursor-pointer text-sm"
                                onClick={() => fileInputRef.current?.click()}
                                disabled={isUploading}
                            >
                                <MdManageSearch size={20} className="mr-1" /> {isUploading ? `Uploading ${progress}%` : 'Procurar Ficheiro'}
                            </button>
                        </div>
                        <input {...getInputProps()} className="hidden" ref={fileInputRef} />
                        <input
                            type="file"
                            accept="image/*"
                            capture="environment"
                            className="hidden"
                            ref={cameraInputRef}
                            onChange={handleCameraCapture}
                        />
                    </>
                )}

                {files.length > 0 && !isUploaded && doc.validation !== false && (
                    <div className="flex flex-col gap-2">
                        {files.map((file) => (
                            <div key={file.name} className="flex justify-between items-center">
                                <p className="flex items-center text-dark/40 font-semibold text-sm">
                                    {getFileIcon(file.name)}
                                    <span className="ml-2">{file.name}</span>
                                    {!file.uploaded && (
                                        <button
                                            type="button"
                                            className="ml-4 text-red-500"
                                            onClick={() => handleRemoveFile(file.name)}
                                        >
                                            <MdClose size={20} />
                                        </button>
                                    )}
                                </p>
                            </div>
                        ))}
                        <div className="md:flex sm:block sm:mt-2">
                            {files.length < MAX_FILES && totalSize < MAX_TOTAL_SIZE_BYTES ? (
                                <button
                                    type="button"
                                    className="md:justify-self-end flex w-fit amco-blue hover:amco-blue/75 text-white font-normal py-2 px-4 rounded cursor-pointer text-sm mr-1 sm:w-full sm:mt-2"
                                    onClick={() => fileInputRef.current?.click()}
                                    disabled={isUploading}
                                >
                                    <MdManageSearch size={20} className="mr-1" /> {'Mais Ficheiros'}
                                </button>
                            ) : totalSize >= MAX_TOTAL_SIZE_BYTES && (
                                <p className="text-sm text-dark font-bold">
                                    Atingiu o limite máximo permitido para carregamento de ficheiros.
                                </p>
                            )}

                            <button
                                type="button"
                                className="flex items-center bg-green-600 text-white py-2 px-4 rounded text-sm sm:mt-2 sm:w-full"
                                onClick={handleUpload}
                                disabled={isUploading}
                            >
                                <MdCloudUpload size={20} className="mr-2" />
                                {isUploading ? `A carregar... (${progress}%)` : 'Enviar Ficheiros'}
                            </button>
                        </div>
                    </div>
                )}

                {files.length > 0 && isUploaded && doc.validation !== false && (
                    <div className="flex flex-col gap-2">
                        {files.map((file) => (
                            <div key={file.name} className="rounded-md">
                                <p className="flex items-center text-sm font-semibold">
                                    {getFileIcon(file.name)}
                                    <span className="ml-2">{file.name}</span>
                                </p>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div >
    );
};

export default DocumentUpload;